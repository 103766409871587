<template>
  <span>
    <v-btn
      icon
      plain
      :disabled="disabled"
      class="mt-1 ml-1 mr-n3"
      @click="open = true"
      @mousedown.stop="!impressions.length ? fetchImpressions() : null"
    >
      <i class="fal fa-lightbulb" />
    </v-btn>
    <v-navigation-drawer
      v-model="open"
      transition="slide-x-reverse-transition"
      :width="$vuetify.breakpoint.mobile ? '100%' : '43%'"
      app
      right
      temporary
    >
      <template #prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-img
                src="/img/onechoice-logo-name.svg"
                :contain="true"
                :max-width="140"
                :max-height="50"
              />
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              :value="hover"
              :content="new_threshold | percentage"
              transition="slide-x-transition"
              color="tertiary lighten-2"
              outlined
              overlap
              left
            >
              <v-hover v-model="hover">
                <v-btn
                  icon
                  :disabled="loading"
                  @click.stop="new_threshold > 0.05 ? new_threshold -= 0.05 : new_threshold = 0; fetchImpressions(false, new_threshold)"
                >
                  <v-icon small>mdi-reload</v-icon>
                </v-btn>
              </v-hover>
            </v-badge>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              icon
              tile
              @click.stop="open = false"
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <smart-filters
          :items="impressions"
          :filter-gender="filterGender"
          @filtered="filteredItems = $event"
        />
        <v-divider />
      </template>
      <v-skeleton-loader
        :loading="impressions.length < 1"
        type="impression@5"
        :types="{
          impression: 'list-item-avatar-two-line, divider'
        }"
        fade-transition
      >
        <v-list
          three-line
          dense
          class="pt-0"
        >
          <template
            v-for="impression, index in filteredImpressions"
          >
            <v-list-item
              :key="`item:${index}`"
              :input-value="impression.match === 1"
              :color="'primary lighten-' + (impression.match < 1 ? Math.round((impression.match - 1) * -5) : 0)"
              @click.stop="$emit('impression-selected', impression); open = false"
            >
              <v-list-item-icon class="mr-3">
                <smart-rank
                  :item="impression"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <smart-factors
                  :item="impression"
                  :organisms="organisms"
                  :resistance-genes="resistanceGenes"
                  :relevant-resistance="relevantResistance"
                  :diagnoses="diagnoses"
                  :allergies="allergies"
                  :gender="filterGender"
                  :age-bracket="ageBracket"
                />
                <div class="text-body-2">
                  <template v-if="impression.oneChoice">
                    <treatment-regimen
                      :treatment="impression.oneChoice"
                      :drug-link="false"
                      style="list-style:none;"
                      show-indication
                    />
                  </template>
                  <template v-if="impression.drugs">
                    <template
                      v-for="(drug, impressionDrugIndex) in impression.drugs"
                    >
                      <template v-if="impressionDrugIndex > 0">&</template>
                      {{ drug.name.en }} {{ drug.impression.dosage }}
                    </template>
                    <template v-if="impression.drugs[0]">x</template>
                  </template>
                  {{ impression.impression_duration }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`divider:${index}`" />
          </template>
          <v-list-item v-if="!filteredImpressions.length">
            <v-list-item-subtitle>No suggestions found</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-navigation-drawer>
  </span>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SmartRank: () => import('@/components/interpretation/SmartRank.vue'),
      SmartFilters: () => import('@/components/interpretation/SmartFilters.vue'),
      SmartFactors: () => import('@/components/interpretation/SmartFactors.vue'),
      TreatmentRegimen: () => import('@/components/interpretation/TreatmentRegimen.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      disabled: Boolean,
      preload: Boolean,
      autoPopulate: {
        type: Boolean,
        default: false,
      },
      filterGender: {
        type: [Boolean, String],
        default: false,
      },
      uuid: {
        type: String,
        required: true,
      },
      organisms: {
        type: Array,
        default: () => ([]),
      },
      resistanceGenes: {
        type: Array,
        default: () => ([]),
      },
      relevantResistance: {
        type: Array,
        default: () => ([]),
      },
      diagnoses: {
        type: Array,
        default: () => ([]),
      },
      allergies: {
        type: Array,
        default: () => ([]),
      },
      threshold: {
        type: Number,
        default: null,
      },
      ageBracket: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        open: false,
        loading: false,
        impressions: [],
        filteredItems: null,
        new_threshold: this.threshold,
        hover: false,
      }
    },
    computed: {
      filteredImpressions () {
        return this.filteredItems ? this.filteredItems : this.impressions.slice(0, 25)
      },
    },
    watch: {
      uuid: function () {
        this.fetchImpressions()
      },
      threshold: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.fetchImpressions(false)
        }
      },
    },
    created () {
      if (this.preload) {
        this.fetchImpressions()
      }
    },
    methods: {
      fetchImpressions (cached = true, threshold = this.threshold, limit = 500, once = false) {
        this.loading = true
        this.impressions = []
        this.axios.get('lab_results/' + this.uuid + '/interpretation/impressions', {
          params: {
            threshold: threshold,
            cache: cached,
            limit: limit,
          },
        })
          .then((response) => {
            if (!response.data.length && !once) {
              this.new_threshold -= 0.05
              if (this.new_threshold > 0) {
                this.fetchImpressions(false, this.new_threshold, 500)
              }
            } else {
              this.impressions = Object.values(response.data)
              this.loading = false
              if (this.autoPopulate && this.impressions[0] && this.impressions[0].oneChoice && this.impressions[0].match >= 0.9) {
                this.selection = this.impressions[0];
                this.$emit('impression-selected', this.selection)
              }
            }
          })
          .catch(error => {
            if (error.response?.data?.message) {
              this.$toast.error(error.response.data.message)
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      close () {
        this.$destroy();
        if (this.$el) {
          this.$el.parentNode.removeChild(this.$el);
        }
      },
    },
  }
</script>
<style scoped>
.text-body-2 {
  line-height: 1.1rem;
  word-break: break-word;
}
</style>
